import React from 'react';
import { animateScroll as scroll } from 'react-scroll';

const Footer = () => {
  return (
    <footer
      style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <div>
        © {new Date().getFullYear()}, Built with
        {` `}
        <a href="https://www.gatsbyjs.org">Gatsby</a>
      </div>
      <span
        role='button'
        tabIndex={0}
        style={{
          cursor: 'pointer',
          color: '#FF662D',
        }}
        onClick={() => scroll.scrollToTop({ duration: 300 })}
        onKeyUp={() => {}}
      >Back to top ↑</span>
    </footer>
  );
};

export default Footer;
