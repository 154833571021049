import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { rhythm } from '../utils/typography';
import Logo from '../png/logo.png';
import ThemeToggle from './theme-toggle';

const Header = ({ location, title, menuLinks }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  return (
    <header
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: rhythm(1.5),
      }}
    >
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          margin: 0,
        }}
      >
        <AniLink
          fade
          style={{
            boxShadow: `none`,
            color: `inherit`,
            display: 'flex',
            alignItems: 'center',
          }}
          to={rootPath}
        >
          <img
            src={Logo}
            style={{
              width: '50px',
              height: 'auto',
              margin: 0,
            }}
            alt={title}
          />
          {title}
        </AniLink>
      </h3>
      <nav
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ul
          style={{
            display: 'flex',
            flex: 1,
            margin: 0,
            marginRight: '10px'
          }}
        >
          {menuLinks && menuLinks.map(menuLink => {
            return (
              <li
                key={menuLink.name}
                style={{
                  listStyle: 'none',
                  padding: '0 0 0 1em',
                  margin: 0,
                }}
              >
                <AniLink fade to={menuLink.link}>
                  {menuLink.name}
                </AniLink>
              </li>
            );
          })}
        </ul>
        <ThemeToggle />
      </nav>
    </header>
  );
};

export default Header;
