import React from 'react';
import useDarkMode from 'use-dark-mode';

import './theme-toggle.css';

const ThemeToggle = () => {
  const darkMode = useDarkMode(true);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <button
        type="button"
        className='theme-toggle__button'
        onClick={darkMode.toggle}
      >
        {darkMode.value ? '☀' : '☾'}
      </button>
    </div>
  );
};

export default ThemeToggle;
